import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { FormikProps, useFormik } from 'formik';
import { createUseStyles } from 'react-jss';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { ADD_VENDOR_PRICE_STATES, HTTP_STATUS, STATUS } from '../../../utils/types';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import SelectPriceProductTypeTemplate from '../../templates/VendorManagement/SelectPriceProductType.template';
import SelectPriceProductTemplate from '../../templates/VendorManagement/SelectPriceProduct.template';
import AddPriceDetailTemplate from '../../templates/VendorManagement/AddPriceDetail.template.';
import PriceManagementReviewTemplate from '../../templates/VendorManagement/PriceManagementReview.template';
import { useVendorPriceService } from '../../../services/useVendorPriceSerivce';

export interface IVendorProductPriceDetails {
    id?: number;
    marketPrice: string;
    reserveSalesPrice: string;
    validityTo: string;
    validityFrom: string;
    name: string;
}

export interface IVendorProductPriceForm {
    productType: string;
    productId: number | null;
    status?: string;
    vendorProductPriceDetails: IVendorProductPriceDetails[];
}

export interface IVendorProductPriceSectionProps {
    formik: FormikProps<IVendorProductPriceForm>;
    setWareHouseId?: Dispatch<SetStateAction<number>>;
    setCurrentSectionTo: (section: ADD_VENDOR_PRICE_STATES) => void;
    warehouses?: { id: number, name: string }[];
    mode?: string
}

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.v2.text.primaryDark
    }
}));

const VendorProductPriceCreatePage: React.FC = () => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const vendorPriceService = useVendorPriceService();
    const vendorInventoryService = useVendorInventoryService();

    const [currentSection, setCurrentSection] = useState<ADD_VENDOR_PRICE_STATES>(ADD_VENDOR_PRICE_STATES.PRODUCT_TYPE);
    const [warehouses, setWarehousesTo] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);

    const fetchWarehouses = async () => {
        if (user?.businessId) {
            try {
                const warehousesResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId });
                if (warehousesResponse.status === HTTP_STATUS.OK) {
                    const warehouses = warehousesResponse?.data?.data?.content;
                    setWarehousesTo(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Warehouses fetch failed');
            }
        }
    };

    const initialValues: IVendorProductPriceForm = {
        productType: '',
        productId: null,
        status: STATUS.ACTIVE,
        vendorProductPriceDetails: [],
    };

    const validationSchema = Yup.object().shape({
        productType: Yup.string().required('Product Type is required'),
        productId: Yup.number().required('Product ID is required').positive('Product ID must be a positive number').integer('Product ID must be an integer'),
        vendorProductPriceDetails: Yup.array().of(
            Yup.object().shape({
                marketPrice: Yup.string().required('Market Price is required'),
                reserveSalesPrice: Yup.string().required('Reverse Sales Price is required'),
                validityTo: Yup.string().required('Price Validity To is required'),
                validityFrom: Yup.string().required('Price Validity From is required')
            })
        )
    });

    const formik = useFormik<IVendorProductPriceForm>({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const { vendorProductPriceDetails } = formik.values;
            setSubmitting(true);
            const body = vendorProductPriceDetails?.map(warehouse => ({
                vendorId: user?.businessId,
                vendorProductId: Number(formik?.values?.productId),
                warehouseId: warehouse?.id,
                marketPrice: warehouse?.marketPrice,
                validityFrom: warehouse?.validityFrom,
                validityTo: warehouse?.validityTo,
                reserveSalesPrice: warehouse?.reserveSalesPrice,
                status: formik?.values?.status
            }));
            try {
                const vendorProductPriceResponse = await vendorPriceService.createProductPrices(body);
                if (vendorProductPriceResponse.status === HTTP_STATUS.OK) {
                    navigate('/dashboard/vendor/price-management');
                }
            }
            catch (error) {
                showSnackbar("error", `Vendor Product Price update failed ${error}`)
            }
        },
    });

    const vendorProductPriceSectionProps: IVendorProductPriceSectionProps = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
        warehouses: warehouses,
    };

    const VendorInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case ADD_VENDOR_PRICE_STATES.PRODUCT_TYPE:
                return <SelectPriceProductTypeTemplate {...vendorProductPriceSectionProps} />;
            case ADD_VENDOR_PRICE_STATES.PRODUCT:
                return <SelectPriceProductTemplate {...vendorProductPriceSectionProps} />
            case ADD_VENDOR_PRICE_STATES.ADD_PRICE:
                return <AddPriceDetailTemplate {...vendorProductPriceSectionProps} />;
            case ADD_VENDOR_PRICE_STATES.REVIEW:
                return <PriceManagementReviewTemplate {...vendorProductPriceSectionProps} />;
            default:
                return <SelectPriceProductTypeTemplate {...vendorProductPriceSectionProps} />;
        }
    }, [currentSection, formik]);

    useEffect(() => {
        fetchWarehouses();
    }, [user?.businessId]);

    return (
        <div className='grid gap-6 justify-items-stretch '>
            <div className={`${classes.titleText} font-semibold text-lg`}>Add Price</div>
            {VendorInventorySectionView}
        </div>
    )
}

export default VendorProductPriceCreatePage