import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { ADD_VENDOR_PRICE_STATES, HTTP_STATUS } from '../../../utils/types';
import { IVendorProductPriceSectionProps } from '../../pages/VendorManagement/VendorProductPriceCreate.page';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { IProductData } from './SelectPriceProduct.template';
import { useSnackbar } from '../../../hooks/useSnackBar';
import Button from '../../atoms/Button/Button';
import ProductViewTemplate from '../DashBoard/Inventory Management/ProductView.template';
import AddPriceSectionTemplate from './AddPriceSection.template';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    textHeading2: {
        color: theme.palette.textV2.primary.primary900
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    textHeading3: {
        color: theme.palette.backgroundV2.primary.primary900
    },
    lineColor: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
}));

const AddPriceDetailTemplate: React.FC<IVendorProductPriceSectionProps> = ({ formik, setCurrentSectionTo, setWareHouseId, mode = 'ADD' }) => {
    const classes = useStyles();
    const vendorInventoryService = useVendorInventoryService();
    const { user } = useAuthenticatedUser();

    const [productsData, setProductsData] = useState<IProductData>({ id: null, skuCode: 'Test', productName: 'testProduct' });
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleBack = () => {
        setCurrentSectionTo(ADD_VENDOR_PRICE_STATES.PRODUCT);
    }
    const onNext = () => {
        setCurrentSectionTo(ADD_VENDOR_PRICE_STATES.REVIEW);
    }

    const getVedorProductbyId = async () => {
        if (user?.businessId && formik?.values?.productId  ) {
            try {
                const productId = formik?.values?.productId ;
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId);
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setProductsData({
                        id: productData.id,
                        skuCode: productData.standardProductDetails.upcCode,
                        productName: productData.productUpcTitle,
                        standardProductDetails: productData.standardProductDetails
                    });
                }
            } catch (error) {
                showSnackbar('error', 'Detailed Product Price fetch failed');
            }
        }
    };
    useEffect(() => {
        getVedorProductbyId();
    }, [formik?.values?.productId]);

    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                {mode !== 'EDIT' && (
                    <>
                        <div className="grid gap-y-3 w-1/2">
                            <div className='flex gap-x-2 mx-auto'>
                                <div className={`${classes.activatedProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                                <div className={`${classes.barHeading} my-auto font-semibold`}>Select Product Type</div>
                            </div>
                            <div className={`${classes.activatedProgressBar} h-2.5 rounded`}></div>
                        </div>

                        <div className="grid gap-y-3 w-1/2">
                            <div className='flex gap-x-2 mx-auto'>
                                <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                                <div className={`${classes.barHeading} my-auto`}>Select Product</div>
                            </div>
                            <div className={`${classes.activatedProgressBar} h-2.5 rounded`}></div>
                        </div>
                    </>
                )}
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Add Pricing Details </div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4 '>
                <div className='flex  justify-between'>
                    <div className=' grid gap-1/2'>
                        <text className={`${classes.textHeading}text-base font-medium`}> Add Pricing Details</text>
                        <text className={`${classes.barHeading}text-xs font-normal`}> Please add pricing details for the locations the product is available </text>
                    </div>
                </div>
                <ProductViewTemplate
                    product={productsData}
                    showButton={false}
                />
                <AddPriceSectionTemplate
                    formik={formik}
                    buttonShow={true}
                    setWareHouseId={setWareHouseId}
                    mode = {mode}
                />
            </div>
            <div className=' flex justify-between'>
                <div className='flex gap-6 '>
                    {mode !== 'EDIT' && (
                        <Button
                            variant="tertiaryContained"
                            label="Back"
                            onClick={handleBack}
                        />
                    )}
                    <Button
                        variant="primaryContained"
                        label="Review"
                        onClick={onNext}
                    />
                </div>
            </div>
        </div>
    )
}

export default AddPriceDetailTemplate