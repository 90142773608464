import { createUseStyles } from "react-jss";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import { FormikProps } from "formik";
import { IVendorProductPriceForm } from "../../pages/VendorManagement/VendorProductPriceCreate.page";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect } from "react";
import SelectV2 from "../../atoms/Select/SelectV2";
import { statusOptions } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    title: {
        color: theme.palette.textV2.primary.primary900,
    },
    textHeading3: {
        color: theme.palette.backgroundV2.primary.primary900
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.primary500,
        width: "18%",
        display: "block",
        " & .MuiFormControlLabel-label": {
            display: "block",
            color: theme.palette.textV2.secondary.secondary800,
            fontSize: "18px",
            fontWeight: "600",
            marginTop: "12px"
        }
    },
    warningAsterisk: {
        color: theme.palette.textV2.primary.primary500
    },
    datePicker: {
        border: "1px solid",
        borderColor: "#DDD",
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        "&:hover": {
            borderColor: "#FD6202",
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: "#DDD",
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `#FD6202 !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `#FD6202 !important`,
        },
    },
}));

interface IPriceInformationProps {
    formik: FormikProps<IVendorProductPriceForm>;
    warehouseId: number;
    index: number;
    warehouseName: any;
    mode: string
}

const PriceInformationTemplate: React.FC<IPriceInformationProps> = ({ warehouseId, formik, index, warehouseName, mode = 'EDIT'}) => {

    const classes = useStyles();

    useEffect(() => {
        formik.setFieldValue(`warehouseDetails[${index}].name`, warehouseName);
        formik.setFieldValue(`warehouseDetails[${index}].id`, warehouseId);
    }, [warehouseName, warehouseId]);

    return (
        <div className="border-3 grid gap-6">
            <text className={`${classes.textHeading3} text-base font-medium `}>Product Pricing (per MT)</text>
            <div className="flex gap-6">
                <div className='w-1/4'>
                    <TextFieldV2
                        variant="outlined"
                        placeholder="Enter Quantity"
                        label="Market Price (CDP2)"
                        {...formik.getFieldProps(`warehouseDetails[${index}].marketPrice`)}
                        type="number"
                    />
                </div>
                <div className='w-1/4'>
                    <TextFieldV2
                        variant="outlined"
                        placeholder="Enter Price"
                        label="Reserve Sales Price (RSP)"
                        {...formik.getFieldProps(`warehouseDetails[${index}].reserveSalesPrice`)}
                        type="number"
                    />
                </div>
                <div className='w-1/4'>
                    <label>Price Validity (To)</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            value={formik?.values?.vendorProductPriceDetails[index]?.validityTo ? moment(formik?.values?.vendorProductPriceDetails[index]?.validityTo, 'YYYY-MM-DD') : null}
                            format="DD-MM-YYYY"
                            onChange={(newValue) => {
                                const formattedDate = moment(newValue).format('YYYY-MM-DD');
                                formik.setFieldValue(`warehouseDetails[${index}].validityTo`, formattedDate);
                            }}
                            className={classes.datePicker}
                        />
                    </LocalizationProvider>
                </div>
                <div className='w-1/4'>
                    <label>Price Validity (From)</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            value={formik?.values?.vendorProductPriceDetails[index]?.validityFrom ? moment(formik?.values?.vendorProductPriceDetails[index]?.validityFrom, 'YYYY-MM-DD') : null}
                            format="DD-MM-YYYY"
                            onChange={(newValue) => {
                                const formattedDate = moment(newValue).format('YYYY-MM-DD');
                                formik.setFieldValue(`warehouseDetails[${index}].validityFrom`, formattedDate);
                            }}
                            className={classes.datePicker}
                        />
                    </LocalizationProvider>
                </div>
                {mode === 'EDIT' && (
                    <div className='w-1/5'>
                        <SelectV2
                            label="Status"
                            value={formik.values.status || ''}
                            onChange={(event: any) => formik.setFieldValue(`warehouseDetails[${index}].status`, event.target.value)}
                            variant="outlined" 
                            options={statusOptions}                       
                             />
                    </div>
                )}
            </div>
        </div>
    )
}

export default PriceInformationTemplate;
