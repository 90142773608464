import { IConfiguration } from "../components/pages/Cart/CartDetails.page-Deprecated";
import { PRODUCTS_TYPES, REGEX, SCREEN_RESOLUTIONS, SCREEN_TYPES, STATUS } from "./constant";
import moment from "moment";
import { ResourceOption, MakeRouteParams } from "./types";
import { createSearchParams, generatePath } from "react-router-dom";
import { IAttribute } from "../components/pages/Catalogue/CatalogueDetails.page";
import { IAttributeForm } from "../components/templates/Catalogue/AttributeSelection.template";
import { IProductSpecification } from "../components/pages/Catalogue/SecondaryCatalogueDetails.page";

export const getScreenType = (screenWidth: number) => {
  if (screenWidth >= SCREEN_RESOLUTIONS.WEBSITE.MIN_WIDTH && screenWidth <= SCREEN_RESOLUTIONS.WEBSITE.MAX_WIDTH) {
    return SCREEN_TYPES.WEBSITE;
  } else if (screenWidth >= SCREEN_RESOLUTIONS.TABLET.MIN_WIDTH && screenWidth <= SCREEN_RESOLUTIONS.TABLET.MAX_WIDTH) {
    return SCREEN_TYPES.TABLET;
  } else {
    return SCREEN_TYPES.MOBILE;
  }
}

export const capitalizeFirstLetter = (input: string) => {
  return input ? `${input.charAt(0)?.toUpperCase()}${input.slice(1)?.toLowerCase()}` : "";
};

export const convertToCamelCase = (input: string) => {
  return input?.split(" ")?.map(el => capitalizeFirstLetter(el)).join(" ")
}

export const convertConstantsToCamelCase = (input: string) => {
  return input?.split("_")?.map(el => capitalizeFirstLetter(el)).join(" ")
}

export const validatePhoneNumber = (phoneNumber: string) => {
  const isValidPhoneNumber = REGEX.PHONE_NUMBER.test(phoneNumber);
  return isValidPhoneNumber;
};

export const timeToLocale = (
  timeInSeconds: number,
  maximumUnitOfTime:
    | "year"
    | "month"
    | "week"
    | "day"
    | "hour"
    | "minute"
    | "second"
) => {
  const timeUnits = [
    { unit: "year", value: 31536000 }, // 365 days
    { unit: "month", value: 2592000 }, // 30 days
    { unit: "week", value: 604800 }, // 7 days
    { unit: "day", value: 86400 }, // 24 hours
    { unit: "hour", value: 3600 }, // 60 minutes
    { unit: "minute", value: 60 }, // 60 seconds
    { unit: "second", value: 1 },
  ];
  let result = "";
  const availableTimeUnits = timeUnits.slice(
    timeUnits.findIndex((timeUnit) => timeUnit.unit === maximumUnitOfTime)
  );

  for (const timeUnit of availableTimeUnits) {
    const unitCount = Math.floor(timeInSeconds / timeUnit.value);
    timeInSeconds %= timeUnit.value;
    if (unitCount > 0) {
      result += `${result ? "," : ""}${unitCount} ${timeUnit.unit}${
        unitCount > 1 ? "s" : ""
        }`;
    }
  }

  return result;
};

export const replaceUrlParams = (
  url: string,
  params: { [key: string]: any }
) => {
  for (const key in params) {
    url = url.replace(`:${key}`, params[key]);
  }
  return url;
};

export const keyToLabel = (key: string): string => {
  const words = key.split(/(?=[A-Z])|\s/);
  const capitalizedWords = words.map(
    (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return capitalizedWords.join(" ");
};

export const formatInIndianNumberSystem = (number: number): string => {
  const splittedNumbers = number?.toFixed(2).toString().split(".");
  const integerPart = splittedNumbers?.[0];
  const decimalPart = splittedNumbers?.length > 1 ? splittedNumbers?.[1] : '00';
  let lastThreeDigits = integerPart?.substring(integerPart?.length - 3);
  let otherNumbers = integerPart?.substring(0, integerPart?.length - 3);
  if (otherNumbers !== '') {
    lastThreeDigits = ',' + lastThreeDigits;
  }
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits + "." + decimalPart;
}

export const toInr = (number: number) => {
  return `₹ ${formatInIndianNumberSystem(number)}`;
}

export const toPercentage = (number: number) => {
  return `${number.toFixed(2)}%`;
}

export const isValidNumber = (value: number, options: { empty?: boolean, negative?: boolean } = {}): boolean => {
  const { empty = false, negative = false } = options;
  if (empty && value === 0) {
    return true;
  }
  let regex = /^[1-9]\d*$/;
  if (negative) {
    regex = /^-?[1-9]\d*$/;
  }
  return regex.test(value.toString());
};

export const convertObjectToOptions = <T extends Record<string, string>>(object: T) =>
  Object.keys(object).map((key) => ({
    value: key,
    label: capitalizeFirstLetter(key.split('_').join(' ').toLowerCase()),
  }));

export const convertConfigurationObjectToOptions = <T extends Record<string, IConfiguration>>(objects: T) => {
  return Object.keys(objects).map((key) => ({
    value: key,
    label: objects[key].name,
  }));
};

export const useDebounce = <T extends any[]>(
  callback: (...args: T) => void,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: T) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const sortSpecificationAttributes = (attributes: any[], rankedAttributes:ResourceOption[] | []) => {

  const rankedSortedAttributes = rankedAttributes?.sort((a: any, b: any) => {
    const indexA = a?.id;
    const indexB = b?.id;
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }
    else if (indexA !== -1) {
      return -1;
    }
    else if (indexB !== -1) {
      return 1;
    }
    else {
      return 0;
    }
  })?.map(attribute => attribute?.name) ?? [];
  if (!attributes) return [];
  const attributeMap = new Map(attributes.map(attribute => [attribute.name.trim(), attribute]));
  const orderedAttributes = rankedSortedAttributes.map(attributeName => attributeMap.get(attributeName.trim()))?.filter(Boolean);
  const remainingAttributes = Array.from(attributeMap.values())?.filter(attribute => !rankedSortedAttributes.includes(attribute?.name.trim()));
  return orderedAttributes.concat(remainingAttributes);
};

export const sortSecondarySpecificationAttributesForMail = (attributes: any[], rankedAttributes:ResourceOption[] | []) => {
  const rankedSortedAttributes = rankedAttributes?.sort((a: any, b: any) => {
    const indexA = a?.id;
    const indexB = b?.id;
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }
    else if (indexA !== -1) {
      return -1;
    }
    else if (indexB !== -1) {
      return 1;
    }
    else {
      return 0;
    }
  })?.map(attribute => attribute?.name) ?? [];
  if (!attributes) return [];
  const attributeMap = new Map(attributes.map(attribute => [attribute.name.trim(), attribute]));
  const orderedAttributes = rankedSortedAttributes.map(attributeName => attributeMap.get(attributeName.trim()))?.filter(Boolean);
  const remainingAttributes = Array.from(attributeMap.values())?.filter(attribute => !rankedSortedAttributes.includes(attribute?.name.trim()));
  return orderedAttributes.concat(remainingAttributes);
};

export const sortSecondarySpecificationAttributes = (attributes: any[], rankedAttributes:ResourceOption[] | []) => {
  const rankedSortedAttributes = rankedAttributes?.sort((a:any,b:any) => a?.id - b?.id).map(attribute => attribute?.name) ?? []
  if(!attributes) return [];
  const formattedAttributes = attributes?.filter(attribute => rankedSortedAttributes?.includes(attribute?.name.trim())).map((attribute, index) => {
    return {
      id: index + 1,
      name: attribute?.name.trim(),
      attributeType: "SPECIFICATION",
      uom: attribute?.uom,
      fieldType: "DROPDOWN",
      minValue: attribute?.minValue,
      maxValue: attribute?.maxValue
    };
  });
  return formattedAttributes;
};

export const sortAttributes = (attributes:any, rankedAttributes:ResourceOption[] | []) => {
  const rankedSortedAttributes = rankedAttributes?.sort((a: any, b: any) => {
    const indexA = a?.id;
    const indexB = b?.id;
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }
    else if (indexA !== -1) {
      return -1;
    }
    else if (indexB !== -1) {
      return 1;
    }
    else {
      return 0;
    }
  })?.map(attribute => attribute?.name) ?? [];

  if(!attributes) return [];
  const orderedAttributes = rankedSortedAttributes?.filter(key => key in attributes).map((key) => { return { key, value: attributes[key] || [] };});
  const remainingAttributes = Object.keys(attributes)?.filter(key => !rankedSortedAttributes?.includes(key)).map(key => ({ key, value: attributes[key] }));
  return orderedAttributes.concat(remainingAttributes);
};

export const getEnumValue = (value: keyof typeof PRODUCTS_TYPES) => {
  return PRODUCTS_TYPES[value] || value;
}

export const getFormattedDate = (dateString:any) => {
  try {
    return moment(dateString).format('YYYY-MM-DD HH:mm:ss');
  } catch (error) {
    return dateString;
  }
};

export const REQUEST_ORDER_STATUS = (status?: string) => {
  switch (status) {
    case STATUS.ONGOING:
      return 'Ongoing';
    case STATUS.ACCEPTED:
      return 'Accepted';
    case STATUS.REJECTED:
      return 'Rejected';
    case STATUS.COMPLETED:
      return 'Completed';
    case STATUS.CANCELLED:
      return 'Cancelled';
    case STATUS.PENDING:
      return 'Pending';
    default:
      return 'Pending';
  }
};

export const snakeCaseToTitleCase = (inputString: string) => {
  return inputString != null ? inputString.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '): "";
}

export const makeRoute = (baseRoute: string, { params, query }: MakeRouteParams): string => {
  const queryString = createSearchParams(query ?? {});
  return `${generatePath(baseRoute, params ?? {})}${queryString ? `?${queryString}` : ''}`;
};

export const sortSingleUpcAttributes = (attributes: IAttribute | {}, rankedAttributes:ResourceOption[] | []): IAttribute | {} => {
  const rankedSortedAttributes = rankedAttributes?.sort((a:any,b:any) => a?.id - b?.id)?.map(attribute => attribute?.name) ?? []
  if (!Array.isArray(attributes)) {
    return [];
  }
  const sortedAttributes = attributes
    ?.sort((a: IAttribute, b: IAttribute) => {
      const nameA = a?.name.trim();
      const nameB = b?.name.trim();
      const indexA = rankedSortedAttributes?.indexOf(nameA);
      const indexB = rankedSortedAttributes?.indexOf(nameB);
      if (indexA === -1 && indexB === -1) {
        return 0;
      } else if (indexA === -1) {
        return 1;
      } else if (indexB === -1) {
        return -1;
      } else {
        return indexA - indexB;
      }
    });
  return sortedAttributes;
};

export const sortMultipleUpcAttributes = (attributes: IAttributeForm, rankedAttributes:ResourceOption[] | []): IAttributeForm[] => {
  const rankedSortedAttributes = rankedAttributes?.sort((a: any, b: any) => {
    const indexA = a?.id;
    const indexB = b?.id;
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }
    else if (indexA !== -1) {
      return -1;
    }
    else if (indexB !== -1) {
      return 1;
    }
    else {
      return 0;
    }
  })?.map(attribute => attribute?.name) ?? [];

  if (typeof attributes !== 'object' || attributes === null || Array.isArray(attributes)) {
    return [];
  }

  const sortedAttributes: IAttributeForm[] = [];
  rankedSortedAttributes?.forEach(key => {
    const trimmedKey = key.trim();
    for (const attributeKey in attributes) {
      const trimmedAttributeKey = attributeKey?.trim();
      if (trimmedAttributeKey === trimmedKey) {
        const attributeMap: IAttributeForm = {[trimmedKey] : attributes?.[attributeKey]};
        sortedAttributes.push(attributeMap)
        break;
      }
    }
  });

  for (const key in attributes) {
    const trimmedKey = key.trim();
    if (!rankedSortedAttributes?.includes(trimmedKey)) {
      const attributeMap: IAttributeForm = { [trimmedKey]: attributes?.[key] };
      sortedAttributes.push(attributeMap);
    }
  }

  return sortedAttributes;
};

export const sortSecondaryAttributes = (attributes: IProductSpecification[], rankedAttributes:ResourceOption[] | []): IProductSpecification[] => {
  const rankedSortedAttributes = rankedAttributes?.sort((a: any, b: any) => {
    const indexA = a?.id;
    const indexB = b?.id;
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }
    else if (indexA !== -1) {
      return -1;
    }
    else if (indexB !== -1) {
      return 1;
    }
    else {
      return 0;
    }
  })?.map(attribute => attribute?.name) ?? [];
  if (!Array.isArray(attributes)) {
    return attributes;
  }
  const filteredAttributes = attributes?.filter((attr: IProductSpecification) => rankedSortedAttributes?.includes(attr?.name.trim()));
  const remainingAttributes = attributes?.filter((attr: IProductSpecification) => !rankedSortedAttributes?.includes(attr?.name.trim()));

  filteredAttributes?.sort((a: IProductSpecification, b: IProductSpecification) => {
    const indexA = rankedSortedAttributes?.indexOf(a?.name.trim());
    const indexB = rankedSortedAttributes?.indexOf(b?.name.trim());
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    } else {
      return 0;
    }
  });
  return filteredAttributes.concat(remainingAttributes);
}

export const sortSecondaryLotAttributes = (attributes: Record<string, string[]>, rankedAttributes:ResourceOption[] | []): Record<string, string[]> => {
  const rankedSortedAttributes = rankedAttributes?.sort((a:any,b:any) => a?.id - b?.id).map(attribute => attribute?.name) ?? []
  const sortedData: Record<string, string[]> = {};
  if (attributes && Object.keys(attributes).length > 0) {
    rankedSortedAttributes?.forEach(key => {
      if (attributes?.hasOwnProperty(key)) {
        sortedData[key] = attributes[key];
      }
    });
    for (const key in attributes) {
      if (!sortedData.hasOwnProperty(key)) {
        sortedData[key] = attributes?.[key];
      }
    }
  }
  return sortedData;
}

export const formatUOM = (value: string | number, uom: string, replace: string = ''): string =>
  `${replace} ${isNaN(Number(value)) ? '' : uom.toLowerCase()}`

export const formatUOMv2 = (value: string | number, uom: string, replace: string = ''): string =>
  `${isNaN(Number(value)) ? '' : uom.toLowerCase()}`

export  const toCamelCase = (inputString: string) => 
  inputString.toLowerCase().replace(/\s\w/g, (match) => match.toUpperCase()
  ).replace(/\s/g, '').replace(/^\w/, (match) => match.toLowerCase());

export const toAllCapsCase = (input: string): string => {
  try {
    return input.toUpperCase().replace(/\s+/g, '_');
  } catch (error) {
    return input;
  }
}
export const toSnakeCase = (input: string): string => {
  try {
    return input.toLowerCase().replace(/\s+/g, '_');
  } catch (error) {
    return input;
  }
}

export const createGstAddressLines = (gstinResponseDTO: any): string[] => {
  const addressLine1 = [
    gstinResponseDTO.principalPlaceFlatNo,
    gstinResponseDTO.principalPlaceBuildingNoFromGST,
    gstinResponseDTO.principalPlaceBuildingNameFromGST,
  ].join(" ");

  const addressLine2 = [
    gstinResponseDTO.principalPlaceStreet,
    gstinResponseDTO.principalPlaceLocality,
  ].join(" ");
  return [addressLine1, addressLine2];
};

export const createGstAdditionalAddressLines = (gstinResponseDTO: any): string[] => {
  const addressLine1 = [
    gstinResponseDTO.flatNo,
    gstinResponseDTO.buildingNo,
    gstinResponseDTO.buildingName,
  ].join(" ");
  const addressLine2 = [
    gstinResponseDTO.street,
    gstinResponseDTO.locality,
  ].join(" ");
  return [addressLine1, addressLine2];
};

export const getListFromLocalStorage = (key: string) => {
  const list = localStorage.getItem(key);
  return list ? JSON.parse(list) : [];
};

const saveToLocalStorage = (newObject: any) => {
  localStorage.setItem('history', JSON.stringify(newObject));
};


export const addToQueue = (newObject: any) => {
  let list = getListFromLocalStorage('history');
  if (list.length > 0 && JSON.stringify(list[list.length - 1]) === JSON.stringify(newObject)) {
    return;
  }

  list.push(newObject);

  if (list.length > 10) {
    list.shift();
  }

  saveToLocalStorage(list);

};

export const getLatestItemByKey = (key: string) => {
  const list = getListFromLocalStorage('history');
  const parsedList = list.map((item: any) => typeof item === 'string' ? JSON.parse(item) : item);
  const filteredList = parsedList.filter((item:any) => item.key === key);
  return filteredList.length > 0 ? (filteredList[filteredList.length - 1]) : null;
};

export const popLastEntry = () => {
  let list = getListFromLocalStorage('history');

  if (list.length === 0) {
    return null;
  }

  const lastItem = list.pop();

  saveToLocalStorage(list);

  return lastItem;
};

export const getProfileDisplayData = (data: any) => {
  if (data === undefined || data === null || (typeof data === 'string' && data.length === 0)) {
    return "No Data";
  }
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      if (data.length === 0) {
        return "No Data";
      }
    } else {
      const values = Object.values(data);
      if (values.length === 0 || values.every(value => typeof value === 'string' && value.length === 0)) {
        return "No Data";
      }
    }
  }
  return data;
};

export const convertDate = (inputDate: string): string => {
  const separator = inputDate.includes('/') ? '/' : '-';
  const [year, month, day] = inputDate.split(separator);
  return `${year}-${month}-${day}`;
};

export const enumToString = (enumValue: string) => {
  switch (enumValue) {
    case Enum.ACTIVE:
      return "Active";
    case Enum.INACTIVE:
      return "Inactive";
    case Enum.PRIMARY_MANUFACTURER:
      return "Primary Manufacturer";
    case Enum.SECONDARY_MANUFACTURER:
      return "Secondary Manufacturer";
    case Enum.END_MANUFACTURER:
      return "End Manufacturer";
    case Enum.ONGOING:
      return "Ongoing";
    case Enum.ACCEPTED:
      return "Accepted";
    case Enum.REJECTED:
      return "Rejected";
    case Enum.COMPLETED:
      return "Completed";
    case Enum.PARTIALLY_COMPLETED:
      return "Partially Completed";
    case Enum.CANCELLED:
      return "Cancelled";
    case Enum.FAILED:
      return "Failed";
    case Enum.INVALID_SHEET:
      return "Invalid Sheet";
    case Enum.PENDING:
      return "Pending";
    case Enum.PUBLISHED:
      return "Published";
    case Enum.UNPUBLISHED:
      return "Unpublished";
    case Enum.NOTPUBLISHED:
      return "Not Published";
    case Enum.STANDARD:
      return "Standard";
    case Enum.NONSTANDARD:
      return "Non Standard";
    case Enum.BLACK_LISTED:
      return "Black Listed";
    case Enum.DRAFT:
      return "Draft";
    default:
      return "";
  }
};

const Enum = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  PRIMARY_MANUFACTURER: "PRIMARY_MANUFACTURER",
  SECONDARY_MANUFACTURER: "SECONDARY_MANUFACTURER",
  END_MANUFACTURER: "END_MANUFACTURER",
  ONGOING: "ONGOING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED",
  PARTIALLY_COMPLETED: "PARTIALLY_COMPLETED",
  CANCELLED: "CANCELLED",
  FAILED: "FAILED",
  INVALID_SHEET: "INVALID_SHEET",
  PENDING: "PENDING",
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  NOTPUBLISHED: "NOTPUBLISHED",
  STANDARD: "STANDARD",
  NONSTANDARD: "NONSTANDARD",
  BLACK_LISTED: "BLACK_LISTED",
  DRAFT: "DRAFT",
};

export const showAttributesUom = (minOption: string, maxOption: string, attributeUom: string) => {
  const parsedValue = parseFloat(minOption);
  let uom = parsedValue ? attributeUom?.toLowerCase() : '';

  if (minOption !== maxOption) {
    return `${minOption} ${uom} - ${maxOption} ${uom}`;
  } else {
    return `${minOption} ${uom ? uom : " "}`;
  }
}

export const formatPrice = (price: number) => {
  let [integerPart, decimalPart] = price.toFixed(2).split('.');
   let lastThree = integerPart.slice(-3);
   let otherNumbers = integerPart.slice(0, -3);
   if (otherNumbers !== '') {
       otherNumbers = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
   }
   const formattedNumber = otherNumbers ? `${otherNumbers},${lastThree}` : lastThree;
   return `₹ ${formattedNumber}.${decimalPart}`;
}

