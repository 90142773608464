import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import ProductViewTemplate from "../DashBoard/Inventory Management/ProductView.template";
import TabsV2, { ITabsSchema } from "../../molecules/TabsV2/TabsV2";
import { useVendorInventoryService } from "../../../services/useVendorInventoryService";
import { IProductData } from "../DashBoard/Inventory Management/SelectProduct.template";
import { ADD_EXISTING_PRODUCT, HTTP_STATUS } from "../../../utils/types";
import ProductWarehouseDetailsTemplate from "./ProductWarehouseDetails.template";
import { AddVendorProductSectionProps } from "./AddExistingProduct.template";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { capitalizeFirstLetter } from "../../../utils/helper";

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
    heading1: {
        color: theme.palette.textV2.primary.primary400,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    subHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
}));

const ProductReviewTemplate: React.FC<AddVendorProductSectionProps> = ({ formik, setCurrentSectionTo, warehouses, onBack, handleCancel, productDetails }) => {

    const classes = useStyles();

    const [activeTab ,setActiveTabTo] = useState("1");

    const schema: ITabsSchema[] = warehouses?.map((warehouse: any, index: number) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <ProductWarehouseDetailsTemplate warehouseId={warehouse.id} formik={formik} index={index} />,
    }));

    const handleBack = () => {
        setCurrentSectionTo(ADD_EXISTING_PRODUCT.PRODUCT_DETAILS);
    }
    const handleTabChange = (tab: string | number) => {
        setActiveTabTo(tab.toString());
    };

    const handleOnSaveDraft = () => {
        formik.setFieldValue("status","DRAFT");
        formik.handleSubmit();
    };
    
    return (
        <div>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm`}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Add Product Details</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Review</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>
            <div className={`${classes.mainHeading} text-base font-medium mt-8`}>Review</div>
            <div className={`${classes.subHeading} text-xs mb-4`}>Please review the data you have entered</div>
            <div className="mt-6">
                <ProductViewTemplate product={productDetails} showButton={false} formik={formik} />
            </div>
            <div className='mt-6'>
                <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
            </div>
            <div className='flex justify-between'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                    />
                    <Button
                        variant="secondaryContained"
                        label="Save As Draft"
                        onClick={handleOnSaveDraft}
                    />
                    <Button
                        variant="primaryContained"
                        label="+Add"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductReviewTemplate;