import { FINISHED_PRODUCTS } from './constant';
export enum AUTH_STATES {
    LOGIN_WITH_EMAIL= "LOGIN_WITH_EMAIL",
    LOGIN_WITH_MOBILE= "LOGIN_WITH_MOBILE",
    USER_SIGNUP= "USER_SIGNUP",
    OTP_VERIFICATION="OTP_VERIFICATION",
    FORGOT_PASSWORD= "FORGET_SET_PASSWORD", 
    EMAIL_REGISTRATION="EMAIL_REGISTRATION",
    SET_PASSWORD="SET_PASSWORD", 
    EMAIL_CONFIRMAITON="EMAIL_CONFIRMAITON" 
}

export enum PREFIX {
    MR= "Mr.",
    MRS= "Mrs."
}

export enum WHATSAPP_LINK {
    PRIMARY= "primary",
    SECONDARY= "secondary"
}

export enum ONBOARDING_STATES {
    GST_INFORMATION = 1,
    BUSINESS_DETAILS = 2,
    ENTITY_TYPE = 3,
    PRODUCT = 4,
    REQUIREMENTS = 5,
}

export enum LOCATION_STATES {
    VIEW_LOCATIONS = "VIEW_LOCATIONS",
	ADD_LOCATION_NO = "ADD_LOCATION_NO",
	ADD_LOCATIONS = "ADD_LOCATIONS",
}

export enum SOCIAL_LINKS {
    FACEBOOK= "FACEBOOK",
    TWITTER= "TWITTER",
    INSTAGRAM= "INSTAGRAM",
    LINKEDIN= "LINKEDIN"
}

export enum HTTP_STATUS {
    OK = 200,
    ACCEPTED = 202,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    INTERNAL_SERVER_ERROR = 500
}

export enum KEY_PERSON_DETAIL_STATES {
    VIEW_KEY_PERSON_DETAILS = "VIEW_KEY_PERSON_DETAILS",
	ADD_KEY_PERSON_NO = "ADD_KEY_PERSON_NO",
	ADD_KEY_PERSON_DETAILS = "ADD_KEY_PERSON_DETAILS",
}

export enum CATALOGUE_LIST_QUERY {
    CLASSES = 'classes',
    CATALOGUE_TYPES = 'catalogueTypes',
    SECONDARY_CATALOGUE_TYPES = 'secondaryCatalogueTypes',
    NON_STANDARD_CATALOGUE_TYPES = 'nonStandardCatalogueTypes',
    STANDARDS = 'standards',
    BRANDS = 'brands',
    GRADES = 'grades',
    SHAPES = 'shapes',
    MANUFACTURERS = 'manufacturers',
    CATEGORY_ID = 'categoryId'
}

export enum CATALOGUE_FILTER_HEADINGS {
    CLASS = 'Class',
    SUB_CLASS = 'Sub class',
    STANDARD = 'Standard',
    BRAND = 'Brand',
    GRADE = 'Grade',
    SHAPE = 'Shape',
    MANUFACTURER = 'Manufacturer'
}

export enum CLASS_STATES {
    STANDARD = "STANDARD",
    NONSTANDARD = "NONSTANDARD",
    SECONDARY = "SECONDARY",
    MELTING_SCRAP = "MELTING_SCRAP"
}

export enum CATALOGUE_TYPE_STATES {
    CURRENT_INVENTORY= "CURRENT_INVENTORY",
    MAKE_TO_ORDER= "MAKE_TO_ORDER"
}

export enum HOMEPAGE_CATALOUGE_SECTION_HEADINGS  {
    TODAYS_HOT_OFFERS= "Today's Hot Offers",
    CHOOSE_YOUR_SPECIFICATIONS= "Choose Your Specifications",
    PROCESS_PRIME= "Process Prime",
    PROCESSED_MATERIAL= "Processed Material"
}

export enum HOMEPAGE_CATALOUGE_SECTION  {
    TODAYS_HOT_OFFERS= "TODAYS_HOT_OFFERS",
    CHOOSE_YOUR_SPECIFICATIONS= "CHOOSE_YOUR_SPECIFICATIONS",
    PROCESS_PRIME= "PROCESS_PRIME",
    PROCESSED_MATERIAL= "PROCESSED_MATERIAL"
}

export interface ResourceOption{
    id: number | string;
    name: string;
    value?: any;
}

export interface PaymentTermsSectionProps {
    value: string,
    heading: string,
    subHeading: string,
    hasNext?: boolean,
    isAvailable:boolean
}

export interface DeliveryAndTransportationSectionProps {
    text: string,
    icon: string,
    description: string,
}

export interface ContactUsPopupProps {
    icon: string, 
    text: string, 
    country?: string,
    mobile?: string, 
    email?: string, 
    description?: string,
}

export interface IPagination {
    size: number;
    totalRecords: number;
    page: number;
    totalPages?: number
}

export interface Attribute {
    id?: number;
	name?: string;
	attributeOptions?: string;
    uom?: string;
    minValue?: string;
    maxValue?: string;
    attributeRank?:number
}

export type MakeRouteParams = {
    params?: { [key: string]: any } | null;
    query?: { [key: string]: any } | null;
};

export enum EMAIL_SUBSCRIPTION_CONTEXT {
    EMAIL_SUBSCRIPTION  = "EMAIL_LETTER"
}

export enum RFQ_SECTIONS {
	PRODUCT_REQUIREMENT = "PRODUCT_REQUIREMENT",
	PERSONAL_DETAILS = "PERSONAL_DETAILS",
}

export enum CATEGORY_TYPE  {
    SUPER_CATEGORY = 0,
    MAIN_CATEGORY = 1,
	PRODUCT_CATEGORY = 2
}

export const INPUT_FIELD_TYPE = {
    CHECKBOX: 'checkbox'
}
export enum CART_SECTIONS {
    CART_DETAILS = "CART_DETAILS",
    PAYMENT_SECTION = "PAYMENT_SECTION",
    CART_REVIEW = "CART_REVIEW",
}

export enum MY_MATERIAL {
   MY_MATERIAL_LISTING="MY_MATERIAL_LISTING",
   CATEGORY_SELECTION="CATEGORY_SELECTION",
   MY_MATERIAL_FORM="MY_MATERIAL_FORM",
   PRODUCT_REQUIREMENT="PRODUCT_REQUIREMENT",
   MY_MATERIAL_PRODUCT="MY_MATERIAL_PRODUCT",
   FINISHED_PRODUCT_LISTING = "FINISHED_PRODUCT_LISTING",
   MAIN_RAW_MATERIAL = "MAIN_RAW_MATERIAL",
   FINISHED_PRODUCT = "FINISHED_PRODUCT",
   SCRAP = "SCRAP",
   SCRAP_LISTING = "SCRAP_LISTING",
}

export enum MY_MATERIAL_LISTING {
    MAIN_RAW_MATERIAL = 1,
    FINISHED_PRODUCT = 2,
    SCRAP = 3
}

export enum USER_PROFILE_TYPE {
    BUYER = 0,
    SELLER = 1
}

export enum MODE {
    VIEW = "VIEW",
    ADD = "ADD",
    UPDATE = "UPDATE",
    EDIT = "EDIT",
}

export enum INVENTORY_MANAGEMENT_LISTING {
    STANDARD_CATALOGUE = 1,
    NON_STANDARD_CATALOGUE = 2,
    PROCESSED_PRIME = 3,
    MELTING_SCRAP = 4,
}

export enum ADD_VENDOR_INVENTORY_STATES {
    PRODUCT_TYPE = "PRODUCT_TYPE",
	PRODUCT = "PRODUCT",
	ADD_USN = "ADD_USN",
    REVIEW = "REVIEW"
}

export enum ADD_VENDOR_PRICE_STATES {
    PRODUCT_TYPE = "PRODUCT_TYPE",
	PRODUCT = "PRODUCT",
	ADD_PRICE = "ADD_PRICE",
    REVIEW = "REVIEW"
}

export interface option {
	value: string;
	label: string;
}

export enum ADD_NEW_PRODUCT_STATE {
    VIEW_PRODUCT = "VIEW_PRODUCT",
	ADD_EXISTING_PRODUCT = "ADD_EXISTING_PRODUCT",
	ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT",
}

export enum ADD_EXISTING_PRODUCT {
    SELECT_PRODUCT = "SELECT_PRODUCT",
    PRODUCT_DETAILS = "PRODUCT_DETAILS",
    PRODUCT_REVIEW = "PRODUCT_REVIEW"
}

export enum PRODUCT_SELECTION_TYPE {
    EXISTING_PRODUCT = "EXISTING_PRODUCT",
    ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT"
}

export enum STATUS {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    DRAFT = "DRAFT"
}

export enum DELIVERY_INFORMATION {
    IMMEDIATE = "IMMEDIATE",
    SEVEN_TO_TEN_DAYS = "SEVEN_TO_TEN_DAYS",
    FIFTEEN_TO_THIRTY_DAYS = "FIFTEEN_TO_THIRTY_DAYS",
    THIRTY_TO_SIXTY_DAYS = "THIRTY_TO_SIXTY_DAYS",
    SIXTY_PLUS_DAYS = "SIXTY_PLUS_DAYS"
}

export enum CLASS_TYPE {
    STANDARD = "STANDARD",
    SECONDARY = "SECONDARY",
    MAKE_TO_ORDER = "MAKE_TO_ORDER",
    NONSTANDARD = "NONSTANDARD"
}

export enum VENDOR_PRODUCTS_STATES {
    PRODUCT_TYPE = "PRODUCT_TYPE",
	PRODUCT = "PRODUCT",
	ADD_USN = "ADD_USN",
    REVIEW = "REVIEW"
}

export enum ADVANCE_FILTER_TYPE {
    VENDOR_PRODUCTS = "VENDOR_PRODUCTS",
    PRODUCTS = "PRODUCTS"
}