import React, {useState } from "react";
import { createUseStyles } from "react-jss";
import TableV2 from "../../organisms/Table/TableV2";
import { ColumnType } from "../../organisms/Table/Table";
import { IVendorProductPriceDetails } from "../../pages/VendorManagement/VendorProductPriceCreate.page";
import { IInventoryAddViewProps } from "./AddPriceSection.template";
import ResourceStatusV2 from "../../atoms/ResourceStatusV2/ResourceStatusV2";
import moment from "moment";
import { enumToString } from "../../../utils/helper";

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    subHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    body: {
        border: `1px solid ${theme.palette.textV2.secondary.secondary50}`,
        borderRadius: '12px'
    },
}));

const VendorProductPriceViewTemplate: React.FC<any> = ({ warehouseId, formik }) => {
    const classes = useStyles();

    const PriceAddView: React.FC<IInventoryAddViewProps> = ({ warehouseId }) => {

        const [productPrices, setproductPricesTo] = useState<IVendorProductPriceDetails[]>(formik.values.warehouseDetails);

        const getTableColumns = () => [
            { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Location", key: "warehouseLocation", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Market Price (CDP2)", key: "marketPrice", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Reserve Sales Price (RSP)", key: "reserveSalesPrice", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Price Validity (To)", key: "validityTo", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Price Validity (From)", key: "validityFrom", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
        ]
        const getRecords = () => {
            return productPrices.map((vendorInventory: IVendorProductPriceDetails, index: any) => ([
                index + 1,
                vendorInventory.name,
                vendorInventory.marketPrice,
                vendorInventory.reserveSalesPrice,
                moment(vendorInventory.validityTo).format("DD-MM-YYYY"),
                moment(vendorInventory.validityFrom).format("DD-MM-YYYY"),
                enumToString(formik?.values?.status),
            ]));
        };

        const getSchema = () => ({
            id: "1",
            pagination: {
                total: 0,
                currentPage: 0,
                isVisible: false,
                limit: 1,
                handleChangePage: () => { },
                handleChangeRowsPerPage: () => { }
            },
            columns: getTableColumns() ?? []
        })
        return (
            <>
                {formik?.values?.warehouseDetails ?
                    <>
                        <div className={` grid gap-4`}>
                            <TableV2 schema={getSchema()} records={getRecords()} />
                        </div>
                    </>
                    :
                    null
                }
            </>
        )
    }

    return (
        <div className={`${classes.body} p-4`}>
            {formik?.values?.warehouseDetails ?
                <div className='w-full relative'>
                    <PriceAddView warehouseId={warehouseId} />
                </div>
                : null}
        </div>
    );
};

export default VendorProductPriceViewTemplate;