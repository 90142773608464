import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { FormikProps, useFormik } from 'formik';
import { createUseStyles } from 'react-jss';
import * as Yup from "yup";
import { ADD_VENDOR_PRICE_STATES, HTTP_STATUS, IPagination, STATUS } from '../../../utils/types';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { usePostProductService } from '../../../services/usePostProductService';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { useNavigate, useParams } from 'react-router-dom';
import { IProductPrice, useVendorPriceService } from '../../../services/useVendorPriceSerivce';
import { CUSTOMER_ROUTES, initialPaginationValues } from '../../../utils/constant';
import { IProductData } from '../../templates/VendorManagement/SelectPriceProduct.template';
import { IproductDetails } from '../../templates/VendorManagement/ProductCatalogueView.template';
import AddPriceDetailTemplate from '../../templates/VendorManagement/AddPriceDetail.template.';
import PriceManagementReviewTemplate from '../../templates/VendorManagement/PriceManagementReview.template';

export interface IAddNewInventoryDetail {
    id?: number;
    marketPrice: string;
    reserveSalesPrice: string;
    validityTo: string;
    validityFrom: string;
    name: string;
}

export interface IAddVendorInventoryForm {
    productType: string;
    productId: number | null;
    status?: string;
    warehouseDetails: IAddNewInventoryDetail[];
}
export interface AddVendorInventorySectionProp {
    formik: FormikProps<IAddVendorInventoryForm>;
    setWareHouseId?: Dispatch<SetStateAction<number>>;
    setCurrentSectionTo: (section: ADD_VENDOR_PRICE_STATES) => void;
    warehouses?: { id: number, name: string }[];
}
const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.v2.text.primaryDark
    },
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
}));
const PriceUpdatePage: React.FC = () => {

    const classes = useStyles();
    const [currentSection, setCurrentSection] = useState<ADD_VENDOR_PRICE_STATES>(ADD_VENDOR_PRICE_STATES.ADD_PRICE);
    const vendorPriceService = useVendorPriceService();
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorInventoryService = useVendorInventoryService();
    const navigate = useNavigate();
    const postProductService = usePostProductService();
    const params = useParams();
    const [productsData, setProductsData] = useState<IProductData | null>(null);
    const [productDetails, setproductDetailsTo] = useState<IproductDetails | null>(null);
    const [productType, setProductType] = useState<string>('')
    const productId = Number(params.id);
    const [warehouseData, setWarehouseData] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);

    const fetchWarehouse = async () => {
        if (user?.businessId) {
            try {
                const wareHouseResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId });
                if (wareHouseResponse.status === HTTP_STATUS.OK) {
                    const warehouses = wareHouseResponse?.data?.data?.content;
                    setWarehouseData(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
    };
    useEffect(() => {
        fetchWarehouse();
    }, [user?.businessId]);

    const getVendorProductDetails = async () => {
        if (user?.businessId) {
            try {
                const productResponse = await postProductService.getProductById(user?.businessId, productId);
                setProductType(productResponse?.data?.data?.productType);
                if (productResponse?.data?.data) {
                    const content: IproductDetails = productResponse.data.data;
                    setproductDetailsTo(content);
                    setProductsData({
                        id: content.id,
                        skuCode: content.standardProductDetails.upcCode,
                        productName: content.productUpcTitle,
                        standardProductDetails: content.standardProductDetails
                    });
                } else {
                    throw new Error("Products fetch failed");
                }
            } catch (error) {
                console.error("Error fetching product details:", error);
            }
        }
    };

    useEffect(() => {
        getVendorProductDetails();
    }, [productId])

    useEffect(() => {
        formik.setFieldValue('productType', productType);
        formik.setFieldValue('productId', productId);
    }, [productType]);

    const getVedorProductPrices = async () => {
        if (user?.businessId) {
            try {
                const params: IProductPrice  = {
                    page: pagination.page,
                    size: pagination.size,
                };
                const vedorInventoriesResponse = await vendorPriceService.getAllPriceProducts(productId, params);
                if (vedorInventoriesResponse.status === HTTP_STATUS.OK) {
                    const vendorInventoryData: IAddNewInventoryDetail[] = vedorInventoriesResponse?.data?.data?.content.map((item: any) => ({
                        id: item.warehouseId,
                        marketPrice: item.marketPrice,
                        reserveSalesPrice: item.reserveSalesPrice,
                        validityFrom: item.validityFrom,
                        validityTo: item.validityTo,
                        name: item.warehouseLocation
                    }));
                    formik.setFieldValue('warehouseDetails', vendorInventoryData);
                    setPagination((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: vedorInventoriesResponse?.data?.data?.totalElements,
                    }));
                }
            } catch (error) {
                showSnackbar('error', 'Vendor Product Price fetch failed');
            }
        }
    };

    useEffect(() => {
        if (productId && user?.businessId) {
            getVedorProductPrices();
        }
    }, [pagination.size, pagination.page]);

    const validationSchema = Yup.object().shape({
        warehouseDetails: Yup.array().of(
            Yup.object().shape({
                marketPrice: Yup.string()
                    .required('Market Price is required'),
                reserveSalesPrice: Yup.string()
                    .required('Reverse Sales Price is required'),
                validityTo: Yup.string()
                    .required('Price Validity To is required'),
                validityFrom: Yup.string()
                    .required('Price Validity From is required')
            })
        )
    });

    const initialValues: IAddVendorInventoryForm = {
        productType: '',
        productId: null,
        status: STATUS.ACTIVE,
        warehouseDetails: [],
    };

    const formik = useFormik<IAddVendorInventoryForm>({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const { warehouseDetails } = formik.values;
            setSubmitting(true);
            const body = warehouseDetails.map(warehouse => ({
                vendorId: user?.businessId,
                vendorProductId: Number(formik.values.productId),
                warehouseId: warehouse.id,
                marketPrice: warehouse.marketPrice,
                validityFrom: warehouse.validityFrom,
                validityTo: warehouse.validityTo,
                reserveSalesPrice: warehouse.reserveSalesPrice,
                status: formik.values.status
            }));
            try {
                const vendorCreateResponse = await vendorPriceService.createProductPrices(body);
                if (vendorCreateResponse.status === HTTP_STATUS.OK) {
                    navigate(`${CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_LISTING}`);
                }
                else {
                    showSnackbar("error", "Business Profile fetch failed.");
                }
            }
            catch (error) {
                showSnackbar("error", `Vendor Product Price  update failed ${error}`)
            }

        },
    });

    const addVendorInventorySectionProps: AddVendorInventorySectionProp = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
        warehouses: warehouseData,
    };

    const VendorInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case ADD_VENDOR_PRICE_STATES.ADD_PRICE:
                return <AddPriceDetailTemplate {...addVendorInventorySectionProps} mode="EDIT" />;
            case ADD_VENDOR_PRICE_STATES.REVIEW:
                return <PriceManagementReviewTemplate {...addVendorInventorySectionProps} mode="EDIT"/>;
            default:
                return <AddPriceDetailTemplate {...addVendorInventorySectionProps}  mode="EDIT" />;
        }
    }, [currentSection, formik]);

    return (
        <div className='grid gap-6 justify-items-stretch '>
            <div className={`${classes.titleText} font-semibold text-lg`}>Update Price</div>
            {VendorInventorySectionView}
        </div>
    )
}

export default PriceUpdatePage